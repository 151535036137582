import React from "react"
import Form from "./shared/Form"
import defaultsData from "../utils/formStructure"

const ChannelOpen = () => {
    return (
        <div className="main-form">
            <div className="container contato-title">
                <h1>CANAL ABERTO WEGA</h1>
            </div>
            <section className="channelOpen">
                <h2>TERMOS DE USO</h2>
                <p>
                A WEGA preza pela integridade, transparência e responsabilidade com todos os seus públicos. Por isso, a informação verdadeira e honesta e o cuidado com a conduta ética de seus líderes, colaboradores e fornecedores são sempre valorizados.
                </p><p>
                Qualquer ato que não estiver de acordo com o código de ética da empresa deve ser reportado. Além disso, qualquer informação ou situação que possa prejudicar a WEGA merece atenção e pode ser relatado com comitê de ética da empresa, que são as únicas pessoas a receberem a informação relatada.
                </p><p>
                Conheça o código de ética da WEGA, <a href="https://admin.wegamotors.com/wp-content/uploads/2025/03/Codigo-de-Conduta-WEGA-2025-compactado.pdf" target="_blank">clicando aqui</a>.
                </p><p>
                Todos os dados pessoais coletados neste canal de denúncias, serão tratados pela WEGA de acordo com as disposições da LGPD – Lei Geral de Proteção de Dados (Lei 13.709/2018 ) e regulamentos pertinentes. Este canal é exclusivo para denuncias que violem o código de conduta. Para outros assuntos use a aba Contato WEGA.
                </p>
                <Form type="Canal Aberto" structure={defaultsData.ChannelOpen} />
            </section>
        </div>
    )
}

export default ChannelOpen;